import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {i18nconfig} from './i18nconfig.js';
import axios from 'axios';

export const initLocalization = () => {
  let params = new URLSearchParams(window.location.search);
  let lang = params.get('lang') && params.get('lang').toLowerCase() || 'en';
  i18nconfig.resources[lang] = {};
  i18n.use(initReactI18next).init(i18nconfig).then(
      () => {
          axios.get(`${process.env.REACT_APP_LOC_URL}${lang.toUpperCase()}.json`)
              .then(response => {
                  let translation = response.data;
                  i18nconfig.resources[lang].translations = translation;
                  i18n.changeLanguage(lang);
              })
              .catch(err => console.error(err));
      }
  );
}
