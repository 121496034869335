import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import 'materialize-css/dist/css/materialize.min.css';
import {RecoilRoot} from 'recoil';
import {initLocalization} from './localization/';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  releaseStage: process.env.REACT_APP_ENV || "development",
  enabledReleaseStages: ['test','production','stage'],
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

initLocalization();

// if (module.hot) {
//   module.hot.accept();
// }

const APP_CONTENT = lazy(() => import('./App.js'));

ReactDOM.render(
  <Suspense fallback={<p>Loading page...</p>}>
    <ErrorBoundary>
      <RecoilRoot>
        < APP_CONTENT />
      </RecoilRoot>
    </ErrorBoundary>
  </Suspense>,
  document.getElementById('root')
);
